body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



.custom-ui {
  background: deepskyblue;
  width: 400px;
  height: 250px;
  text-align: center;
  color: white;
}

.custom-ui button {
  background-color: transparent;
  border: 1px solid white;
  width: 150px;
  height: 2rem;
  margin: 1rem;
  color: white;
}